<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1 w-250px me-2">
          <el-select filterable size="large" :placeholder="generalConstants.SELECTTRADINGPARTNER" v-model="formData.tradingPartnerId" @change="getTradingPartnerTemplateContent(formData.tradingPartnerId)">
            <el-option :label="tradingData.store_name" :value="tradingData.trading_partner_id" :key="tradingData"  v-for="tradingData in tradingPartnerData"></el-option>
          </el-select>
        </div>
        <el-tooltip class="box-item" effect="dark" :content="generalConstants.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-primary"  v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-template' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ generalConstants.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-template_type="{ row: data }">
          {{ data.template_type }}
        </template>
        <template v-slot:cell-template_title="{ row: data }">
          {{ data.template_title }}
        </template>
        <template v-slot:cell-actions="{ row: data }">
          <div v-if="permission.isEditAllowed || permission.isDeleteAllowed">
            <el-tooltip class="box-item" effect="dark" :content="generalConstants.EDIT" placement="top">
              <button type="button" v-if="permission.isEditAllowed" @click.prevent="$router.push({ name: 'edit-template', params: { id: data.template_id } })" class="btn btn-icon btn-light btn-sm me-2">
                <i class="bi bi-pencil-square"></i>
              </button>
            </el-tooltip>
            <el-tooltip class="box-item" effect="dark" v-if="permission.isDeleteAllowed && data.tradingPartnerId != ''" :content="generalConstants.DELETE" placement="top">
              <button type="button" @click.prevent="remove(data.template_id, tableData.findIndex((i) => i.template_id === data.template_id))" class="btn btn-icon btn-light btn-sm">
                <i class="bi bi-trash text-danger"></i>
              </button>
            </el-tooltip>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from 'element-plus'
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";

export default {
  name: "template-management",
  components: {
    Datatable
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const search = ref("");
    const pageSize = ref(10);
    const total = ref(0);
    const tableData = ref([]);
    const currentpage = ref(1);
    const loading = ref(false);
    const generalConstants = globalConstant.general;
    const noDataFound = ref(generalConstants.LOADING);
    const tradingPartnerData = reactive([]);
    const store = useStore();
    const updateProps = ref(null);
    
    const templateTypes = ['','Job Ticket', 'Shipment Label', 'Package Slip', 'Invoice'];
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const searchData = ref(store.getters.getTemplateManagementSearch(moduleName.value));
    const searchParams = reactive({
      search: searchData.value.search,
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
      tradingPartnerId: searchData.value.tradingPartnerId
    });
    const formData = ref({
      tradingPartnerId: searchData.value.tradingPartnerId,
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
    })
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: 'Template Type',
        key: "template_type",
        sortable: true,
        width: "w-100px",
      },
      {
        name: 'Template Name',
        key: "template_title",
        sortable: true,
        width: "w-100px",
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    const getTradingPartnerTemplateContent = (val) => {
      searchParams.tradingPartnerId = val;
      rememberSearch();
      getJobTickets();
    }

    //Get all Trading Partners
    const getTradingPartners = async () => {
      await ApiService.query("get-active-trading-partners")
      .then(({ data }) => {
        tradingPartnerData.push({
          trading_partner_id: '',
          store_name: 'Default',
        });
        data.data.forEach((obj) => {
          tradingPartnerData.push({
            trading_partner_id: obj.trading_partner_id != '' ? obj.trading_partner_id : '',
            store_name: obj.get_user_oauth[0].name,
          });
        });
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    //Get all Template Contents
    const getJobTickets = async () => {
      loading.value = true;
      await ApiService.query("template-management", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            tableData.value = [];
            data.data.data.forEach((obj) => {
              tableData.value.push({
                template_id: obj.template_id,
                template_title: obj.template_title,
                template_type: templateTypes[obj.template_type],
                tradingPartnerId: obj.trading_partner_id != '' ? obj.trading_partner_id : ''
              });
            })
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = [];
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, generalConstants.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
          tableData.value = [];
          loading.value = false;
        });
    }

    //Delete Trading Partner Record
    const remove = async (templateId, index) => {
      ElMessageBox.confirm(
        generalConstants.DELETE_MESSAGE,
        generalConstants.ALERT_WARNING,
        {
          confirmButtonText: generalConstants.ALERT_CONFIMATION,
          cancelButtonText: generalConstants.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      )
      .then(() => {
        ApiService.delete("template-management/"+ templateId)
        .then((response) => {
          let status = response.status;
          let message = response.data.message;
          if (status == 200) {
            notificationFire(message, 'success');
            tableData.value.splice(index, 1);
            total.value = total.value > 0  ? total.value - 1 : 0;
            setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          }
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
      })
    };

    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      getJobTickets();
    };

    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getJobTickets();
    };

    //Client side search functionality
    const filterTableData = computed(() =>
      tableData.value.filter(
        (data) =>
          (!search.value ||
          data.name.toLowerCase().includes(search.value.toLowerCase()))
      )
    )

    const handleSearch = () => {
      rememberSearch();
      handleCurrentChange(1);
    }

    //Reset search value
    const resetSearch = () => {
      formData.value.tradingPartnerId = '';
      searchParams.tradingPartnerId = '';
      rememberSearch();
      getJobTickets();
    }

    const rememberSearch = () => {
      let actionParams = {
          "tradingPartnerId":searchParams.tradingPartnerId,
      }
      store.dispatch(Actions.SHIPPING_PACKAGE_SEARCH, {'module':moduleName.value,'params':actionParams});
    };

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createTemplate") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateTemplate") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteTemplate") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      await getTradingPartners();
      await getJobTickets();
    })

    return {
      tableData,
      search,
      getJobTickets,
      total,
      currentpage,
      pageSize,
      handleSizeChange,
      handleCurrentChange,
      remove,
      handleSearch,
      resetSearch,
      loading,
      filterTableData,
      noDataFound,
      generalConstants,
      searchParams,
      tableHeader,
      formData,
      tradingPartnerData,
      getTradingPartnerTemplateContent,
      permission,
      updateProps
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
</style>